import React from 'react'
import styled from 'styled-components'
import SectionsContainer from '../../../components/SectionsContainer'

const GridTable = styled.article`
  display: grid;
  grid-template-columns: max-content auto;
  text-align: left;
  grid-gap: 10px 38px;
  margin-top: 16px;

  p {
    margin: 0;
  }

  .th {
    font-weight: 600;
  }

  ~ .headline--reunion {
    margin-top: 40px;
  }

  ul:not([class]) {
    margin: 0;

    + p {
      margin: 0;
    }
  }

  & + p {
    margin-top: 16px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-gap: 15px 80px;
  }
`

const Venue = () => {
  /*
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { eq: "#wildflower-venue-map" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const venueMaps = data.allContentfulAsset.edges

  return (
    <SectionsContainer>
      <section className="wrap section body-content">
        {venueMaps.map(({ node: venue }) => (
          <section className="section" key={venue.id}>
            <h3 className="headline--reunion center-align">{venue.title}</h3>
            <img src={venue.file.url} alt={venue.title} />
          </section>
        ))}
      </section>
    </SectionsContainer>
  )
  */
  return (
    <SectionsContainer>
      <section className="wrap section body-content">
        <h3 className="headline--reunion center-align">Hotel</h3>
        <p>Wake up where the clouds are far behind you.</p>
        <p>
          Signia by Hilton<br />
          159 Northside Drive NE Atlanta, Georgia
        </p>
        <ul>
          <li>Standard King - $259 USD</li>
          <li>Standard Queen - $269 USD</li>
          <li>Skyview Deluxe King - $279 USD</li>
          <li>Skyview Deluxe Queen - $289 USD</li>
        </ul>
        <p>
          <a
            className="btn--submit"
            style={{ textDecoration: 'none' }}
            href="https://book.passkey.com/go/SEINTFALL2024"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now ↗
          </a>
        </p>
      </section>
    </SectionsContainer>
  )
}
export default Venue
